import $ from "jquery"
import AOS from "aos"
import { docReady } from "./_helper"
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import youtube from "./youtube";

import tippy from "tippy.js";
import 'tippy.js/dist/tippy.css';

const menuToggle = document.querySelector(".menu__toggle")
const headerNavigation = document.querySelector(".header__navigation")
const navigationToggle = document.querySelectorAll(
    ".navigation__children > .navigation__link"
)
const infoToggle = document.querySelector(".info__toggle")
const toggleGallery = document.querySelector(".toggle__gallery")
AOS.init()

docReady(() => {
    if (menuToggle) {
        menuToggle.addEventListener("click", () => {
            headerNavigation.classList.toggle("is-active")
            menuToggle.classList.toggle("is-active")
            if (headerNavigation.classList.contains("is-active")) {
                document.body.style.overflowY = "hidden"
            } else {
                document.body.style.overflowY = ""
            }
        })
    }
    Fancybox.bind("[data-fancybox]", {
        // Your custom options
    });
    navigationToggle.forEach((item) => {
        item.addEventListener("click", (e) => {
            e.preventDefault();

            const dropdown =
                item.parentElement.parentElement.querySelector(".dropdown")
            let value = dropdown.getAttribute("aria-expanded")

            if (value == "true") {
                dropdown.setAttribute("aria-expanded", "false")
            } else {
                dropdown.setAttribute("aria-expanded", "true")
            }
        })
    })

    if (window.innerWidth > 960) {
        headerNavigation.classList.remove("is-active")
        menuToggle.classList.remove("is-active")
    }

    //   document.querySelector(".info__toggle").addEventListener("click", () => {
    //     this.siblings(".info__box").classList.toggle("info__box--open")
    //   })
    //   $(".info__toggle").on("click", () => {
    //     $(this).siblings(".info__box").toggleClass("info__box--open")
    //   })

    $(".toggle__gallery").on("click", () => {
        $(".slide__first").find('[data-fancybox="images"]').trigger("click")
    })

    if (infoToggle) {
        infoToggle.addEventListener("click", () => {
            this.siblings(".info__box").classList.toggle("info__box--open")
        })
    }

    if (toggleGallery) {
        toggleGallery.addEventListener("click", () => {
            $(".slide__first").find('[data-fancybox="images"]').trigger("click")
        })
    }

    youtube();
    tippy('[data-tippy-content]');
})

if (window.location.href.indexOf("uff") > -1) {
    $("#uff.modal").css("visibility", "visible")
}

$(".modal__close").on("click", function () { })
// $("#UserForm_Form_107_action_process").click(function (e) {
//   e.preventDefault()

//   $.ajax({
//     // url: "process_data.php",
//     type: "POST",
//     data: $("#modal form").serialize(),
//     success: function (data) {
//       console.log(data)
//     },
//     error: function (XMLHttpRequest, textStatus, errorThrown) {
//       if (!window.console) console = { log: function () {} }
//       console.log(JSON.stringify(XMLHttpRequest), textStatus, errorThrown)
//     },
//   })
// })

import YouTubePlayer from 'youtube-player';

export default function youtube()
{
    const youtubeSelector = document.querySelector(".youtube");
    const youtubeID = document.querySelector('#youtube')?.dataset.youtubeId;

    if (!youtubeID) { return }

    let player = YouTubePlayer('youtube', {
        videoId: youtubeID,
        width: '100%',
        height: window.innerWidth > 960 ? 700 : 400
    });
}
